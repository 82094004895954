import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import moment from 'moment'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
import Modal from 'reboron/OutlineModal'
// Settings
import { modalStyle, modalContentStyle, validators, path } from 'Constants'
import ValueStoryShare from '../ValueStory/ValueStoryShare'
// Actions
import {
  domainDetails,
  domainDetailsSuccess,
  domainDetailsFailure,
  deleteDomain,
  updateValueStoryOwner,
  fetchAllValueStories,
  fetchAllActiveUsers
}
  from 'actions/userActions'

  const valueStoryModalContentStyle = Object.assign({}, modalContentStyle);
  valueStoryModalContentStyle.padding = "20px";
  
  const valueStoryModalStyle = Object.assign({}, modalStyle);
  valueStoryModalStyle.width = "100%";
  valueStoryModalStyle.maxWidth = "1250px";
  valueStoryModalStyle.height = "99%";

class ReassignValueStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filterText: "", message: "" ,valuestoryDetails:[],
    VSID : 0,
    activeUsers:[],
    isLoading: false,
    }
    
    this.loadData = this.loadData.bind(this)   
    this.handleUserInput = this.handleUserInput.bind(this)
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.openShareValueStory = this.openShareValueStory.bind(this)
  }

  loadData() {
    this.setState({ isLoading: true});
    this.props.fetchAllValueStories()
      .then((results) => {
        this.setState({ valuestoryDetails: results.data.Result });
        this.props.fetchAllActiveUsers()
          .then((results) => {
            this.setState({ activeUsers: results.data.Result ,isLoading: false});
          }).catch((error) => {
            if (error.stack.includes('Network Error')) {
              this.props.domainDetailsFailure('API service is down. Please contact administrator.')
            }
            else {
              this.props.domainDetailsFailure(error.response.data.Message)
            }
            this.setState({ isLoading: false});
          })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.domainDetailsFailure('API service is down. Please contact administrator.')
        }
        else {
          this.props.domainDetailsFailure(error.response.data.Message)
        }
        this.setState({ isLoading: false});
      })
  }

  componentDidMount() {
    this.loadData()
  }

  handleUserInput(filterText) {
    this.setState({ filterText: filterText.target.value.toLowerCase() });
  };

  // handleRowDel(domainDetail) {
  //   const result = window.confirm('Do you want to delete the domain ' + domainDetail.name + ' ? ');
  //   if (result) {
  //     var index = this.state.valuestoryDetails.map(function (x) { return x.id; }).indexOf(domainDetail.id);
  //     if (index === -1) {
  //       return false;
  //     }
  //     this.props.deleteDomain(domainDetail.id)
  //       .then((results) => {
  //         this.setState({ message: results.data.Message });
  //         this.state.valuestoryDetails.splice(index, 1);
  //         this.setState(this.state.valuestoryDetails);
  //       }).catch((error) => {
  //         if (error.stack.includes('Network Error')) {
  //           this.props.domainDetailsFailure('API service is down. Please contact administrator.')
  //         }
  //         else {
  //           this.props.domainDetailsFailure(error.response.data.Message)
  //         }
  //       })
  //   }
  // };

  // handleAddEvent(evt) {
  //   var domainDetail = {
  //     name: evt
  //   }
  //   this.state.valuestoryDetails.push(domainDetail);
  //   this.setState(this.state.valuestoryDetails);
  // }

  handleEditEvent(evt) {
    var item = {
      name: evt.ownerName,
      id: evt.id,
      isEditing: evt.isEditing
    };
    var old_domainDetails = this.state.valuestoryDetails.slice();
    var newdomainDetails = old_domainDetails.map(function (testdomainDetails) {
      for (var key in testdomainDetails) {
        if (testdomainDetails.VSID == item.id) {
          testdomainDetails.OwnerName = item.name;
          testdomainDetails.isEditing = !item.isEditing;

          // if (evt.UpdatedBy !== undefined) {
          //   testdomainDetails.UpdatedBy = evt.UpdatedBy;
          //   testdomainDetails.UpdatedOn = evt.UpdatedOn;
          // }
          break;
        }
      }
      return testdomainDetails;
    });

    this.setState({ valuestoryDetails: newdomainDetails });

  }

  handleCancelEvent(originalDetails) {
    var item = {
      id: originalDetails[0].id,
      name: originalDetails[0].name
    };

    var old_domainDetails = this.state.valuestoryDetails.slice();
    var newdomainDetails = old_domainDetails.map(function (testdomainDetails) {
      for (var key in testdomainDetails) {
        if (testdomainDetails.VSID === item.id) {
          testdomainDetails[key] = item.name;
          testdomainDetails["isEditing"] = false;
          break;
        }
      }
      return testdomainDetails;
    });
    this.setState({ valuestoryDetails: newdomainDetails });

  };  

  handleDomainDetailTable(evt) {
    var item = {
      name: evt.target.name,
      id: evt.target.id,
      value: evt.target.value
    };

    var old_domainDetails = this.state.old_domainDetails.slice();
    if (old_domainDetails.length === 0) {
      old_domainDetails = this.state.valuestoryDetails.slice();
      this.setState({ old_valuestoryDetails: old_domainDetails });
    }
    let testOfObjects = old_domainDetails;
    var newdomainDetails = testOfObjects.map(function (testdomainDetails) {
      for (var key in testdomainDetails) {
        if (testdomainDetails.id === item.id) {
          testdomainDetails[key] = item.name;
          testdomainDetails["isEditing"] = false;
          break;
        }
      }
      return testdomainDetails;
    });

    this.setState({ valuestoryDetails: newdomainDetails });

  };

  handleUpdateEvent(updateDetails) {
    if (updateDetails.length > 0 && !updateDetails[0].name == '' && this.state.activeUsers.some(x=>x.Email == updateDetails[0].name)) {
      var item = {
        VSId: updateDetails[0].id,
        OwnerId: this.state.activeUsers.filter(x=>x.Email == updateDetails[0].name)[0].Id,
        ownerName: updateDetails[0].name,
        id: updateDetails[0].id,
        isEditing: updateDetails[0].isEditing
      };

      this.props.updateValueStoryOwner(item)
        .then((results) => {
          if (results.status === 200) {   
           // this.state.valuestoryDetails.filter(x=>x.VSID == updateDetails[0].id)[0].isEditing = false
           //item.UpdatedBy = results.data.Result.UpdatedBy;
           //item.UpdatedOn = results.data.Result.UpdatedOn;
           this.setState({ message: results.data.Message });
           this.handleEditEvent(item)
          }
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.props.domainDetailsFailure('API service is down. Please contact administrator.')
          }
          else {
            this.props.domainDetailsFailure(error.response.data.Message)
          }
        })
    }
    else {
      this.props.domainDetailsFailure('Invalid email')
    }
  }

   
  // componentWillUnmount() {
  //   this.formDispatch(actions.reset('addDomain'))
  // }
  openShareValueStory(VSID){
    this.setState({ VSID: VSID },() => {this.showModal("shareValueStory");});
  }

  showModal(action) {
		this.refs[action].show()
	}

  hideModal(action) {
		this.refs[action].hide()
	}

  render() {
    return (
      <div className="popup-content">
        {/* <div className="medium-12 title">{this.props.content.title}</div> */}
        <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            src={path + `images/ajax-loader.gif`} alt="" /> : null}
                </div>
        <div className="success">{this.state.message}</div>
        {(this.state.valuestoryDetails.length > 1) ?
          <div className="grid-x" className="medium-11 medium-offset-0">
            
            <SearchBar filterText={this.state.filterText} onUserInput={this.handleUserInput.bind(this)} />

            <DomainDetailTable
              onDomainTableUpdate={this.handleDomainDetailTable.bind(this)}
              // onRowAdd={this.handleAddEvent.bind(this)}
              //onRowDel={this.handleRowDel.bind(this)}
              valuestoryDetails={this.state.valuestoryDetails}
              activeUsers={this.state.activeUsers}
              filterText={this.state.filterText}
              onRowEdit={this.handleEditEvent.bind(this)}
              onRowCancel={this.handleCancelEvent.bind(this)}
              onRowUpdate={this.handleUpdateEvent.bind(this)}
              validation={this.props.validation}
              openShareValueStory={(e) => this.openShareValueStory(e)}
            />
          </div> : ""}
          <div>
					<Modal
						ref="shareValueStory"
						modalStyle={valueStoryModalStyle}
						contentStyle={valueStoryModalContentStyle}>
						{this.props.user.valueStories.constructor === Array ? 
						<ValueStoryShare
						  SelectedVSData = {this.state.valuestoryDetails.filter(vs=>vs.VSID== this.state.VSID)}
							VSId={this.state.VSID}
							Content={this.props.menu.valueStory}
							IsShared={false}
						/>
						:<span style={{color:'red'}}>this.props.user.valueStories.payload</span>}
						<button onClick={() => this.hideModal('shareValueStory')} className="close" />
					</Modal>
				</div>
      </div>
    );
  }
}


class SearchBar extends React.Component {
  handleChange(event) {
    this.props.onUserInput(event);
  }
  render() {
    return (
      <div >
        <Control.text
          model="OwnerName"
          id="OwnerName"
          component={Textfield}
          label={'Search...'}
          floatingLabel
          ref="filterTextInput"
          onChange={this.handleChange.bind(this)}
        />
      </div>
    );
  }
}

class DomainDetailTable extends React.Component {
  constructor(props) {
    super(props);
    this.openShareValueStory = this.openShareValueStory.bind(this)
  }
  openShareValueStory(VSID){
    this.props.openShareValueStory(VSID);
  }

  render() {
    var openShareValueStory = this.openShareValueStory;
    var onDomainDetailTableUpdate = this.props.onDomainTableUpdate;
    var rowDel = this.props.onRowDel;
    var rowEdit = this.props.onRowEdit;
    var rowCancel = this.props.onRowCancel;
    var rowUpdate = this.props.onRowUpdate;
    var filterText = this.props.filterText;
    var isEditing = this.props.isEditing;
    var validation = this.props.validation;
    var activeUsers = this.props.activeUsers;
    var valuestoryDetail = this.props.valuestoryDetails.map(function (valuestoryDetail) {
      if (valuestoryDetail.ValueStoryName.toLowerCase().indexOf(filterText) === -1
         && valuestoryDetail.OwnerName.toLowerCase().indexOf(filterText) === -1
         && valuestoryDetail.CreatedBy.toLowerCase().indexOf(filterText) === -1
      ) {
        return;
      }
      // else if (valuestoryDetail.OwnerName != null && valuestoryDetail.OwnerName.toLowerCase().indexOf(filterText) === -1) {
      //   return;
      // }
      return (<DomainDetailRow
        onDomainDetailTableUpdate={onDomainDetailTableUpdate}
        valuestoryDetail={valuestoryDetail}
        activeUsers={activeUsers}
        // onDelEvent={rowDel.bind(this)}
        onEditEvent={rowEdit.bind(this)}
        onCancelEvent={rowCancel.bind(this)}
        onUpdateEvent={rowUpdate.bind(this)}
        key={valuestoryDetail.id}
        validation={validation}
        openShareValueStory={(e) => openShareValueStory(e)}
      />)
    });
    return (
      <div    >
        <table className="basic-table table">
          <thead>
            <tr>
              <th>Value Story Name</th>
              <th>Created By</th>
              <th>Owner Name</th>
              <th>First Name</th>
              <th>Last Name</th>
              {/* <th>Department</th> */}
              {/* <th>Added By/On</th>
              <th>Updated By/On</th> */}
              <th>Update</th>
              <th>Share</th>
            </tr>
          </thead>
          <tbody>
            {valuestoryDetail}  {onDomainDetailTableUpdate}
          </tbody>
        </table>
      </div>
    );
  }
}

class DomainDetailRow extends React.Component {
  constructor(props) {
    super(props);
    this.openShareValueStor = this.openShareValueStory.bind(this)
  }
  // onDelEvent() {
  //   this.props.onDelEvent(this.props.domainDetail);
  // }
  onEditEvent() {
    this.props.onEditEvent(this.props.domainDetail);
  }

  onValuestoryTableUpdate() {
    this.props.onDomainDetailTableUpdate()
  }

  openShareValueStory(VSID){
    this.props.openShareValueStory(VSID);
  }

  render() {
    return (
      this.props.valuestoryDetail.isEditing ?
        <EditableCell onDomainTableUpdate={this.props.onValuestoryTableUpdate}
          domainDetail={this.props.valuestoryDetails}
          activeUsers={this.props.activeUsers}
          cellData={{
            name: this.props.valuestoryDetail.ValueStoryName,
            id: this.props.valuestoryDetail.VSID,
            ownerName:this.props.valuestoryDetail.OwnerName,
            ownerId:this.props.valuestoryDetail.OwnerId,
            createdBy:this.props.valuestoryDetail.CreatedBy,
            firstName: this.props.valuestoryDetail.FirstName,
            lastName: this.props.valuestoryDetail.LastName,
            department: this.props.valuestoryDetail.Department,
            isEditing: this.props.valuestoryDetail.isEditing
          }}
          onCancelEvent={this.props.onCancelEvent}
          onUpdateEvent={this.props.onUpdateEvent}
          validation={this.props.validation}
        />
        :
        <NonEditableRow
          valuestoryDetail={this.props.valuestoryDetail}
          cellData={{
            name: this.props.valuestoryDetail.ValueStoryName,
            id: this.props.valuestoryDetail.VSID,
            ownerName: this.props.valuestoryDetail.OwnerName, // added by sunita as need description while adding into language json files 
            createdBy:this.props.valuestoryDetail.CreatedBy,
            firstName: this.props.valuestoryDetail.FirstName,
            lastName: this.props.valuestoryDetail.LastName,
            department: this.props.valuestoryDetail.Department,
            isEditing: false//this.props.valuestoryDetail.isEditing
          }}
          //onDelEvent={this.props.onDelEvent}
          onEditEvent={this.props.onEditEvent} 
          openShareValueStory={(e) => this.openShareValueStory(e)}
        />
    );
  }
}

class EditableCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valuestoryDetails: this.props.cellData,
      originalvaluestoryDetails: this.props.cellData
    };
  }

  onDomainTableUpdate() {
    this.props.onDomainDetailTableUpdate();
  }

  onCancelEvent() {

    this.state.valuestoryDetails = [
      {
        id: this.state.originalvaluestoryDetails.id,
        name: this.state.originalvaluestoryDetails.name,
        createdBy: this.state.originalvaluestoryDetails.createdBy,
        ownerName: this.state.originalvaluestoryDetails.ownerName, 
        ownerId: this.state.originalvaluestoryDetails.ownerId, 
        firstName: this.state.originalvaluestoryDetails.firstName,
        lastName: this.state.originalvaluestoryDetails.lastName,
        department: this.state.originalvaluestoryDetails.department,
        isEditing: false
      }]
    this.props.onCancelEvent(this.state.valuestoryDetails);
  }

  onUpdateEvent() {
    this.props.onUpdateEvent(this.state.valuestoryDetails);
  }

  onChangeEvent(event) {
    var changeItem = [
      {
        id: event.target.id,
        name: event.target.value,
        isEditing: true
      }]
    this.setState({ valuestoryDetails: changeItem });
  }

  openShareValueStory(VSID){
    this.props.openShareValueStory(VSID);
  }

  isInEmailList(vals) {
    if(vals === undefined){
      return true;
    }
    else{
      return  this.props.activeUsers.some(e => e.Email === vals);
    }    
  }

  render() {
    return (
      <tr>
        <td>
          <span id={this.props.cellData.id}
            name={this.props.cellData.name}
            isEditing={this.props.cellData.isEditing}>{this.props.cellData.name}</span>
        </td>
        <td>
          <span>{this.props.cellData.createdBy}</span><br />
        </td>
        <td>
          <datalist id="suggestions">
            {this.props.activeUsers.map(function (users) {
              return <option>{users.Email}</option>
            })}
          </datalist>
            
          <Control.text
            autoComplete="on" list="suggestions"
            model="editDomain.OwnerName"
            component={Textfield}
            defaultValue={this.props.cellData.ownerName}
            id={this.props.cellData.id}
            value={this.state.valuestoryDetails.ownerName}
            onChange={this.onChangeEvent.bind(this)}
            isEditing={this.props.cellData.isEditing}
            validateOn="blur"
            validators={{
              required: validators.required,
              validEmail: validators.validEmail,
              inEmailList: (vals) => this.isInEmailList(vals)
            }}
            type='name' />
          <Errors
            className="errors"
            model="editDomain.OwnerName"
            show="touched"
            messages={{
              required: this.props.validation.required,
              validEmail: this.props.validation.validEmail,
              //validDomainName: this.props.validation.validDomainName,
              //duplicate: this.props.validation.duplicateDomain
              inEmailList: this.props.validation.emailInList
            }} />
        </td>
        <td>
          <span>{this.props.cellData.firstName}</span><br />
        </td>
        <td>
          <span>{this.props.cellData.lastName}</span><br />
        </td>  
        {/* <td>
        <span>{this.props.cellData.department}</span><br />
        </td>  */}
        
        {/* <td>
          <span>{this.props.domainDetail.AddedBy}</span><br />
          <span>{moment(this.props.domainDetail.AddedOn).format('L')}</span>
        </td>
        <td>
          <span> {this.props.domainDetail.UpdatedBy}</span> <br />
          <span>{moment(this.props.domainDetail.UpdatedOn).format('L')} </span>
        </td> */}
        <td>
          <button onClick={this.onUpdateEvent.bind(this)}>Update</button>
          <button onClick={this.onCancelEvent.bind(this)}>Cancel</button>
        </td>
        <td >          
          <div className="grid-x grid-padding-x">
            <div className="medium-5">
              <div className="medium-12" onClick={()=> this.openShareValueStory(this.props.cellData.id)}>
                <img width="23" src="images/share-value-story.png" />
                {/* <span style={{ 'fontSize': '12px' }}>Share</span> */}
              </div>
            </div>

          </div>
        </td>
      </tr>
    );
  }
}

class NonEditableRow extends React.Component {

  // onDelEvent() {
  //   this.props.onDelEvent(this.props.cellData);
  // }

  onEditEvent() {
    this.props.onEditEvent(this.props.cellData);
  }

  openShareValueStory(VSID){
    this.props.openShareValueStory(VSID);
  }

  render() {
    return (
      <tr>
        <td>
          <span id={this.props.cellData.id}
            name={this.props.cellData.name}
            isEditing={this.props.cellData.isEditing}>{this.props.cellData.name}</span>
        </td>
        <td>
          <span>{this.props.cellData.createdBy}</span><br />
        </td>
        <td>
          <span>{this.props.cellData.ownerName}</span><br />
        </td>
        <td>
          <span>{this.props.cellData.firstName}</span><br />
        </td>
        <td>
          <span>{this.props.cellData.lastName}</span><br />
        </td>  
        {/* <td>
        <span>{this.props.cellData.department}</span><br />
        </td>  */}
        {/* <td>
          <span>{this.props.domainDetail.AddedBy}</span><br />
          <span>{moment(this.props.domainDetail.AddedOn).format('L')}</span>
        </td>
        <td>
          <span> {this.props.domainDetail.UpdatedBy}</span> <br />
          <span>{moment(this.props.domainDetail.UpdatedOn).format('L')} </span>
        </td> */}
        <td>
          <button onClick={this.onEditEvent.bind(this)}>Edit</button>
        </td>
        <td>
          <div className="grid-x grid-padding-x">
            <div className="medium-5">
              <div className="medium-12" onClick={() => this.openShareValueStory(this.props.cellData.id)}>
                <img width="23" src="images/share-value-story.png" />
                {/* <span style={{ 'fontSize': '12px' }}>Share</span> */}
              </div>
            </div>

          </div>
        </td>
        {/* <td>
          <button onClick={this.onDelEvent.bind(this)}>Delete</button>
        </td> */}
      </tr>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    valuestoryDetails: state.valuestorDetails,
    validation: state.content.validation,
    user: state.default,
    content : state.content.menu.DomainManager[0].domainDetails,
    menu: state.content.menu
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllValueStories: (formValues) => dispatch(fetchAllValueStories(formValues)),
    fetchAllActiveUsers: (formValues) => dispatch(fetchAllActiveUsers(formValues)),   
    domainDetailsSuccess: (bool) => dispatch(domainDetailsSuccess(bool)),
    domainDetailsFailure: (error) => dispatch(domainDetailsFailure(error)),
    deleteDomain: (id) => dispatch(deleteDomain(id)),
    updateValueStoryOwner: (valueStoryDetails) => dispatch(updateValueStoryOwner(valueStoryDetails))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReassignValueStory)
